<template>
  <div>
    <div class="page-header clear-filter">
      <div class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
          "
        >
          Benutzer aktualisieren
        </div>
        <div class="card">
          <div
            class="
              tab-content tab-space
              text-left
              tab-content-padding
              text-center
            "
          >
            <div class="container" style="color: black">
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Benutzer Name"
                  placeholder="Benutzer Name"
                  v-model="userName"
                  type="text"
                  :disabled="true"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Passwort"
                  placeholder="Passwort"
                  v-model="password"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <div style="width: 50%;"><fg-input
                  class="col-sm-12 col-12"
                  label="Blocked Datum"
                  placeholder="Blocked Datum"
                  :value="dateFormat(blockedDate)"
                  type="email"
                  :disabled="true"
                />
                </div>
                <div style="width: 50%;">
                  <fg-input
                  class="col-sm-12 col-12"
                  label="Erstellungsdatum"
                  placeholder="Erstellungsdatum"
                  :value="dateFormat(createdDate)"
                  type="email"
                  :disabled="true"
                />
                </div>
              </div>
             
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Manuel Verlängern"
                  placeholder="TT/MM/YYYY"
                  v-model="manuelDate"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Notizen"
                  placeholder=""
                  v-model="info"
                  type="text"
                >
                </fg-input>
              </div>

              <div class="row" style="float: right; margin: 40px">
                <button
                  class="btn btn-round btn-question"
                  @click="updateInfo()"
                >
                  Info Speichern
                </button>
                <button
                  class="btn btn-round btn-question"
                  @click="updateUser()"
                >
                  Password Ändern
                </button>

                <button
                  class="btn btn-round btn-question"
                  @click="changeManuelDate()"
                >
                  Datum Verlängern
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "../../../../components/";

export default {
  components: {
    FgInput,
  },
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      userName: null,
      blockedDate: null,
      password: null,
      createdDate:null,
      id: null,
      manuelDate: null,
      info:null,
    };
  },
  mounted() {
    this.userName = this.$route.params.userName;
    this.blockedDate = this.$route.params.userBlockedDate;
    this.createdDate = this.$route.params.userCreatedDate;
    this.id = this.$route.params.userId;
    this.info=this.$route.params.info;
    if (this.id == null) {
      this.$router.push({
        name: "userList",
      });
    }
  },
  methods: {
    updateUser() {
      this.$axios
        .post(this.baseUrl + "/changePassword", {
          userId: this.id,
          password: this.password,
        })
        .then((response) => {
          this.$modal.show("dialog", {
            title: "Regiter Info",
            text: "Kennwort wurde erfolgreich aktualisiert",
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        })
        .catch((error) => {
          this.$modal.show("dialog", {
            title: "Password Error",
            text: error,
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        });
    },
    changeManuelDate() {
      this.$axios
        .post(this.baseUrl + "/changeManuelDate", {
          userId: this.id,
          manuelDate: this.manuelDate,
        })
        .then((response) => {
          this.$modal.show("dialog", {
            title: "Update Info",
            text:
              "Ihr Konto(" +
              this.userName +
              ") wurde bis " +
              this.manuelDate +
              " verlängert.",
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        })
        .catch((error) => {
          this.$modal.show("dialog", {
            title: "Password Error",
            text: error.response.data.message[0].messages[0].message,
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        });
    },
    updateInfo() {
      this.$axios
        .post(this.baseUrl + "/updateInfo", {
          userId: this.id,
          info: this.info,
        })
        .then((response) => {
          this.$modal.show("dialog", {
            title: "Update Info",
            text:
              "Informarion Message wurde gespeichert",
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        })
        .catch((error) => {
          this.$modal.show("dialog", {
            title: "Info Update Error",
            text: error.response.data.message[0].messages[0].message,
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        });
    },
    dateFormat: function (date) {
      if (date == undefined) {
        return "";
      }
      return this.$moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="page-header clear-filter">
    <div class="container">
      <div class="card">
        <div
          class="
            tab-content tab-space
            text-left
            tab-content-padding
            text-center
          "
        >
          <div class="container" style="color: black">
            <div class="title">Benutzerverwaltung</div>
            <div class="nav-item" v-if="loginButton === false && isAdmin()">
            
              <button
                v-popover:popover1
                class="navbar-brand btn btn-question"
                @click="routeToPage('newUser')"
              >
                Neue Benutzer
              </button>
            
            </div>
            
            <!-- <div style="margin-bottom: 10px; width: 200px">
              <el-input v-model="filters[0].value"></el-input>
            </div> -->
            <div style="margin-bottom: 10px">
             <el-row>
              <el-col :span="6">
                <el-input v-model="filters[0].value" placeholder="Benutzer Name"></el-input>
              </el-col>
            </el-row>
           </div>
            <data-tables
              :data="users"
              :filters="filters"
              :action-col="actionCol"
              style="width: 100%"
            >
              <el-table-column
                prop="username"
                label="Benutzer Name"
                sortable="custom"
                key="username"
                :min-width="15"
                :max-width="20"
              ></el-table-column>

              <el-table-column
                prop="blockedDate"
                label="Block Datum"
                sortable="custom"
                :formatter="dateFormat"
                :min-width="20"
                :max-width="20"
              ></el-table-column>
            </data-tables>
          </div>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      users: [],
      actionCol: {
        label: "Ändern",
        props: {
          align: "center",
        },
        buttons: [
          {
            props: {
              type: "primary",
              icon: "el-icon-edit",
            },
            handler: (row) => {
              this.editRow(row);
            },
            label: "Bearbeiten",
          },
          {
            props: {
              type: "primary",
              icon: "el-icon-edit",
            },
            handler: (row) => {
              this.addMonths(row, 1);
            },
            label: "+1 Monat",
          },
          {
            props: {
              type: "primary",
              icon: "el-icon-edit",
            },
            handler: (row) => {
              this.addMonths(row, 2);
            },
            label: "+2 Monaten",
          },
          {
            props: {
              type: "primary",
              icon: "el-icon-edit",
            },
            handler: (row) => {
              this.addMonths(row, 3);
            },
            label: "+3 Monaten",
          },
          {
            props: {
              type: "primary",
              icon: "el-icon-edit",
            },
            handler: (row) => {
              this.addMonths(row, 0);
            },
            label: "Deaktiv",
          },
        ],
      },
      filters: [
        {
          value: "",
          prop: "username",
        },
      ],
      loginButton: true,
    };
  },
  mounted() {
    this.$axios
      .get(this.baseUrl + "/getUserList")
      .then((response) => (this.users = response.data));
      this.currentUser = this.$store.getters.getUser;
    if (this.currentUser && this.currentUser.id) {
      this.loginButton = false;
      this.$store.commit("setShowLoginButton", false);
    } else {setShowLoginButton
      this.loginButton = true;
      this.$store.commit("setShowLoginButton", true);
    }
  },
  methods: {
    dateFormat: function (row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return this.$moment(date).format("DD-MM-YYYY ");
    },
    editRow(row) {
      this.$router.push({
        name: "editUser",
        params: {
          userId: row.id,
          userName: row.username,
          userBlockedDate: row.blockedDate,
          userCreatedDate: row.created_at,
          info:row.info
        },
      });
    },
    addMonths(row, months) {
      let date = new Date();
      date.setMonth(date.getMonth() + months);
      let userId = row.id;
      this.$axios
        .post(this.baseUrl + "/updateBlockedDate", {
          userId: userId,
          blockedDate: date.toISOString().replace('-', '/').split('T')[0].replace('-', '/'),
        })
        .then((response) => {
          let modalText =
            months == 0
              ? "Benutzer Konto " + row.username + " ist deaktiviert"
              : "Ihr Konto(" +
                row.username +
                ") wurde bis " +
                date.getDate() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear() +
                " verlängert.";
          this.$modal.show("dialog", {
            title: "Update Info",
            text: modalText,
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        })
        .catch((error) => {
          this.$modal.show("dialog", {
            title: "Error",
            text: error.response.data.message[0].messages[0].message,
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        });
      },
    isAdmin() {
      return (
        this.currentUser != null &&
        this.currentUser.userRole != null &&
        this.currentUser.userRole.id == 1
      );
    },
    routeToPage(pageName) {
        this.$router.push('/'+pageName);
         document.getElementById("navbarButton").click();
    },
  },
  watch: {
    showLoginButton: function (newValue, oldValue) {
      this.currentUser = this.$store.getters.getUser;
      this.loginButton = newValue;
    },
  },
};
</script>

<style>
</style>